

import { ApplicationService } from './shared/services/application.service';
import { BehaviorSubject } from 'rxjs';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { ShowHelpCommand } from './shared/models/help-model';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import introJs from 'intro.js/intro.js';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  static isBrowser = new BehaviorSubject<boolean>(null);

  title = 'sesc-hoteis-spa';
 

  constructor(
    private service: ApplicationService,
    private translate: TranslateService,
    private config: PrimeNGConfig,
    @Inject(PLATFORM_ID) private platformId: any
  ) {

    AppComponent.isBrowser.next(isPlatformBrowser(platformId));

    if (isPlatformBrowser(platformId)) {
      if (self != top) {
        top.location = self.location;
      }

      let introJS = introJs();

      this.service.onHelpNotifier.subscribe((resp: ShowHelpCommand) => {

        this.service.lastHelpCommand = resp;
        //var isActivateHelp = localStorage.getItem('isActivateHelp') == 'true' ? true : false;
  
        //if (isActivateHelp || resp.force) {
        introJS.setOptions({
          steps: resp.options,
          nextLabel: 'Ok',
          prevLabel: 'Voltar',
          //skipLabel: 'Pular',
          doneLabel: 'Ok',
          hidePrev: true,
          showStepNumbers: false,
          scrollToElement: false
        });
  
        introJS.start();
  
      });
    }

    this.translate.use('pt_br');
    this.translate.setDefaultLang('pt_br');
    this.translate.get('primeng').subscribe(res => this.config.setTranslation(res));


    

  }
}
